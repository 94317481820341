<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="systemMessage in systemMessages"
        :key="systemMessage.name"
      >
        {{ systemMessage.title }}
        <b-icon-pencil-square
          class="ml-3 mt-1 clickable float-right"
          variant="primary"
          style="font-size: 1.2em"
          @click="getSystemMessage(systemMessage)"
        />
        <system-message-activity-log
          class="float-right"
          :bot-id="botId"
          :system-message="systemMessage"
        />
      </b-list-group-item>
    </b-list-group>
    <b-modal
      centered
      v-model="modalSystemMessage"
      :title="selectedSystemMessage.title"
    >
      <b-form ref="form-system-message">
        <div v-if="selectedSystemMessage.type === 'answer'">
          <answer-text
            :answer-text.sync="answer"
            :intents="intents"
          ></answer-text>
        </div>
        <div v-else>
          <b-input
            class="form-control-sm"
            v-model.trim="message"
            required
          ></b-input>
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="modalSystemMessage = false"
          >Cancel
        </b-button>
        <b-button variant="primary" @click="setSystemMessage"
          >Save Changes
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import AnswerText from '@/components/Intents/AnswerTypeText';
import SystemMessageActivityLog from '@/components/Bots/SystemMessageActivityLog';

export default {
  /*global _*/
  mixins: [httpclient],
  components: { AnswerText, SystemMessageActivityLog },
  props: ['botId', 'systemMessages'],
  mounted() {
    this.getIntents(this.botId);
  },
  data() {
    return {
      modalSystemMessage: false,
      selectedSystemMessage: {},
      formItem: {
        botId: -1,
        systemMessageType: '',
        intentAnswers: [],
      },
      intentAnswer: {
        weight: 0,
        answerType: 1,
        answer: '',
      },
      answer: {
        type: 'text',
        content: {
          message: '',
        },
      },
      answerDefault: {
        type: 'text',
        content: {
          message: '',
        },
      },
      intents: [],
    };
  },
  computed: {
    message: {
      get() {
        return this.answer.content.message;
      },
      set(newValue) {
        const vm = this;
        vm.answer.content.message = newValue;
        vm.answer = _.cloneDeep(_.assign({}, vm.answer));
      },
    },
  },
  watch: {
    answer(newVal) {
      const vm = this;
      vm.intentAnswer.answer = JSON.stringify(newVal);
      vm.intentAnswer = _.cloneDeep(_.assign({}, vm.intentAnswer));
    },
    botId(newVal) {
      this.getIntents(newVal);
    },
  },
  methods: {
    async setSystemMessage() {
      this.modalSystemMessage = false;
      const confirmMessage = 'Set the system message?';
      const confirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {
        centered: true,
      });
      if (confirm) {
        const intentAnswer = _.cloneDeep(_.assign({}, this.intentAnswer));
        this.formItem.intentAnswers = [];
        this.formItem.intentAnswers.push(intentAnswer);
        const resp = await this.httpclient.post(
          '/api/bots/' +
            this.botId +
            '/system-messages/' +
            this.formItem.systemMessageType,
          this.formItem
        );
        if (resp.data.isSuccess) {
          await this.$bvModal.msgBoxOk(
            'System message had been updated successfully. The changes will take place within 5 minutes.',
            {
              okVariant: 'success',
              centered: true,
            }
          );
        }
      } else {
        this.modalSystemMessage = true;
      }
    },
    async getSystemMessage(data) {
      this.selectedSystemMessage = data;
      this.formItem.botId = this.botId;
      this.formItem.systemMessageType = data.name;
      const url = encodeURI(
        '/api/bots/' +
          this.botId +
          '/system-messages/' +
          this.formItem.systemMessageType
      );
      const resp = await this.httpclient.get(url);
      if (resp.data) {
        const apiResp = resp.data;
        try {
          const answer = JSON.parse(apiResp.data[0].answer);
          this.answer = _.cloneDeep(_.assign({}, this.answerDefault, answer));
        } catch (ex) {
          this.answer = _.cloneDeep(_.assign({}, this.answerDefault));
        }
      }
      this.modalSystemMessage = true;
    },
    async getIntents(botId) {
      const resp = await this.httpclient.get('/api/bots/' + botId + '/intents');
      if (this.botId === botId) {
        this.intents = resp.data;
      } else {
        this.intents = null;
      }
    },
  },
};
</script>
